import { FC, useEffect, useMemo, useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';

import { useAppContext } from '@contexts/AppContext';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { sum } from 'lodash';
import ordinal from 'ordinal';

import API from '@api/API';

import {
  Entertainer,
  useCurrentEntertainerQuery,
  useTopEntertainersQuery,
} from '@queries/EntertainerQueries';

import { useAnalytics } from '@hooks/useAnalytics';

import { useNavigate } from '@router/index';

import Button from '@components/buttons/Button';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';
import { BASIC_RATING_COLOR_RECORD } from '@components/overlays/CritiqueOverlay/CritiqueOverlayConstants';

dayjs.extend(utc);

const boostEntertainerProfile = ({ entertainerId }: { entertainerId: string }) => {
  return API.post(`/v1/user/entertainer/${entertainerId}/boost`, {});
};

export const BoostProfileSection: FC<{
  city: { _id: string; slug?: string; name: string };
  currentEntertainer: Entertainer & { boostExpiresAt?: string };
  entertainerBasicRatings: Record<string, number>;
  type: Entertainer['type'];
  back: string;
}> = ({ city: currentCity, currentEntertainer, entertainerBasicRatings, type, back }) => {
  const [expiresAt, setExpiresAt] = useState<Date>(() => {
    if (currentEntertainer.boostExpiresAt) {
      return dayjs.utc(currentEntertainer.boostExpiresAt).local().toDate();
    }

    return undefined;
  });
  const [remainingSeconds, setRemainingSeconds] = useState<number>();
  const [remainingTimeFormatted, setRemainingTimeFormatted] = useState<string>();
  const { userIsLoggedIn } = useAppContext();
  const navigate = useNavigate();
  const { track } = useAnalytics();

  const { topEntertainers } = useTopEntertainersQuery(currentCity._id, type, userIsLoggedIn);

  const handleBoostProfile = async () => {
    track('Click boost profile');

    if (!currentEntertainer) {
      return;
    }

    await boostEntertainerProfile({ entertainerId: currentEntertainer._id });
    setExpiresAt(dayjs().add(8, 'hours').toDate());
  };

  const pieChartData: { key: string; value: number; color: string }[] = useMemo(() => {
    const total = sum(Object.values(entertainerBasicRatings ?? {}));

    return Object.entries(entertainerBasicRatings)
      .filter(([, value]) => value)
      .map(([key, value]: [string, number]) => ({
        key,
        value: Math.round((value / total) * 100),
        color: BASIC_RATING_COLOR_RECORD[key],
      }));
  }, [entertainerBasicRatings]);

  const currentEntertainerRank = useMemo(() => {
    if (!topEntertainers?.length || !currentEntertainer) {
      return undefined;
    }

    return topEntertainers.find((entertainer) => entertainer._id === currentEntertainer._id);
  }, [currentEntertainer, topEntertainers]);

  useEffect(() => {
    if (expiresAt) {
      const initialSeconds = dayjs(expiresAt).diff(dayjs(), 'seconds');
      setRemainingSeconds(initialSeconds > 0 ? initialSeconds : null);

      const interval = setInterval(() => {
        setRemainingSeconds((prevSeconds) => {
          if (prevSeconds === null || prevSeconds <= 0) {
            clearInterval(interval);
            setExpiresAt(undefined);
            return null;
          }
          return prevSeconds - 1;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [expiresAt]);

  const buttonText = useMemo(() => {
    if (remainingSeconds) {
      const remainingTimeFormatted = dayjs()
        .startOf('day')
        .add(remainingSeconds, 'seconds')
        .format('H[h] mm[m] ss[s]');
      return `Boost Active! Time remaining: ${remainingTimeFormatted}`;
    }

    return 'Boost my profile';
  }, [remainingSeconds]);

  return (
    <div
      className="d-grid gap-2 mt-4 p-3"
      style={{ border: '1px solid rgba(255,255,255,.7)', borderRadius: 8 }}
    >
      <TitleToolbar
        text={currentEntertainer.title}
        size="md"
        action={
          <Button
            variant="smooth"
            color="neutral"
            size="sm"
            className="px-3"
            roundness="rounded"
            onClick={() => {
              track('Manage Profile');
              navigate('/manage/entertainer', {
                state: {
                  back,
                },
              });
            }}
          >
            Manage Profile
          </Button>
        }
      />
      <div className="d-flex gap-3 align-items-center">
        <div style={{ width: '30%' }}>
          <PieChart
            rounded
            lineWidth={25}
            radius={50}
            data={pieChartData}
            paddingAngle={25}
            // label={({ dataEntry }) => `${dataEntry.value}%`}
            labelStyle={(index) => ({
              fill: pieChartData[index].color,
              fontSize: '12px',
              fontWeight: 'bold',
              fontFamily: 'Rubik',
              letterSpacing: 1,
            })}
          />
        </div>
        <div style={{ width: '70%' }}>
          <p className="mt-0">
            You're currently ranked in {ordinal(currentEntertainerRank?.rank ?? 0)}! Boost your
            profile for 8hrs to promote yourself and get more ratings from the community.
          </p>
        </div>
      </div>
      {expiresAt ? (
        <Button roundness="rounded" variant="smooth">
          {buttonText}
        </Button>
      ) : (
        <Button
          startIcon={<FontAwesomeIcon name="chart-line" />}
          roundness="rounded"
          onClick={handleBoostProfile}
        >
          {buttonText}
        </Button>
      )}
    </div>
  );
};
