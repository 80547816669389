import {
  App as F7App,
  Link,
  LoginScreen,
  Page,
  Tab,
  Tabs,
  Toolbar,
  View,
  Views,
  f7ready,
} from 'framework7-react';
import { ComponentProps, FC, ReactElement, memo, useEffect } from 'react';

import { queryClient } from '@contexts/queryClient';

import { fetchCurrentEntertainer, useCurrentEntertainerQuery } from '@queries/EntertainerQueries';
import { fetchCurrentPromoter } from '@queries/PromoterQueries';

import { ChatScreen } from '@screens/ChatScreen/ChatScreen';
import { CommunityScreen } from '@screens/CommunityScreen/CommunityScreen';
import { ConversationsScreen } from '@screens/ConversationsScreen/ConversationsScreen';
import { CreateEditBookingOpportunityEventScreen } from '@screens/CreateEditEventScreen/CreateEditBookingOpportunityEventScreen';
import { CreateEditEntertainerEventScreen } from '@screens/CreateEditEventScreen/CreateEditEntertainerEventScreen';
import { CreateEditPromoterEventScreen } from '@screens/CreateEditEventScreen/CreateEditPromoterEventScreen';
import { CritiqueScreen } from '@screens/CritiqueScreen/CritiqueScreen';
import { DiscoverScreen } from '@screens/DiscoverScreen/DiscoverScreen';
import { EditProfileScreen } from '@screens/EditProfileScreen';
import { EventCleanupScreen } from '@screens/EventCleanupScreen';
import { EventScreen } from '@screens/EventScreen';
import { EventsScreen } from '@screens/EventsScreen';
import { HomeScreen } from '@screens/HomeScreen/HomeScreen';
import { InterestScreen } from '@screens/InterestScreen/InterestScreen';
import { InterestsScreen } from '@screens/InterestsScreen/InterestsScreen';
import { LogInScreen } from '@screens/LogInScreen/LogInScreen';
import { MagicLinkScreen } from '@screens/MagicLinkScreen';
import { NotificationsScreen } from '@screens/NotificationsScreen/NotificationsScreen';
import { ProfileScreen } from '@screens/ProfileScreen';
import { PromoterScreen } from '@screens/PromoterScreen';
import { QueryScreen } from '@screens/QueryScreen/QueryScreen';
import { QuickPicksScreen } from '@screens/QuickPicksScreen/QuickPicksScreen';
import { SearchScreen } from '@screens/SearchScreen/SearchScreen';
import { SettingsScreen } from '@screens/SettingsScreen';
import { SmsResponseScreen } from '@screens/SmsResponseScreen';
import { SmsScreen } from '@screens/SmsScreen';
import { SubmitVenueScreen } from '@screens/SubmitVenueScreen';
import { SuggestedEventsScreen } from '@screens/SuggestedEventsScreen/SuggestedEventsScreen';
import { TabScreen } from '@screens/TabScreen';
import { TriviaScreen } from '@screens/TriviaScreen/TriviaScreen';
import { ChallengesScreen } from '@screens/challenge/ChallengesScreen';
import { BookingRequestScreen } from '@screens/entertainer/BookingRequestScreen';
import { EditEntertainerProfileScreen } from '@screens/entertainer/EditEntertainerProfileScreen';
import { EntertainerCompetitionScreen } from '@screens/entertainer/EntertainerCompetitionScreen/EntertainerCompetitionScreen';
import { EntertainerDetailsScreen } from '@screens/entertainer/EntertainerDetailsScreen/EntertainerDetailsScreen';
import { EntertainerRankingsScreen } from '@screens/entertainer/EntertainerRankingsScreen/EntertainerRankingsScreen';
import { EventRequestsScreen } from '@screens/entertainer/EventRequestsScreen/EventRequestsScreen';
import {
  ManageEntertainerBookingOpportunityScreen,
  ManageNoauthBookingOpportunityScreen,
} from '@screens/entertainer/ManageEntertainerBookingOpportunityScreen/ManageEntertainerBookingOpportunityScreen';
import { ManageEntertainerBookingRequestScreen } from '@screens/entertainer/ManageEntertainerBookingRequestScreen/ManageEntertainerBookingRequestScreen';
import { ManageEntertainerProfileScreen } from '@screens/entertainer/ManageEntertainerProfileScreen';
import { OnboardEntertainerScreen } from '@screens/entertainer/OnboardEntertainerScreen/OnboardEntertainerScreen';
import { NoAuthDownloadScreen } from '@screens/noauth/NoAuthDownloadScreen/NoAuthDownloadScreen';
import { NoAuthQuickPickScreen } from '@screens/noauth/NoAuthQuickPickScreen/NoAuthQuickPickScreen';
import { OnboardingBasicInfoScreen } from '@screens/onboarding/OnboardingBasicInfoScreen/OnboardingBasicInfoScreen';
import { OnboardingCritiquesScreen } from '@screens/onboarding/OnboardingCritiquesScreen/OnboardingCritiquesScreen';
import { OnboardingInterestsScreen } from '@screens/onboarding/OnboardingInterestsScreen/OnboardingInterestsScreen';
import { OnboardingQuickPicksScreen } from '@screens/onboarding/OnboardingQuickPicksScreen/OnboardingQuickPicksScreen';
import { CreateEditBookingOpportunitesScreen } from '@screens/promoter/CreateEditBookingOpportunityScreen/CreateEditBookingOpportunityScreen';
import { EditPromoterProfileScreen } from '@screens/promoter/EditPromoterProfileScreen/EditPromoterProfileScreen';
import { ManageCompetitionsScreen } from '@screens/promoter/ManageCompetitionsScreen';
import { ManagePromoterBookingOpportunityScreen } from '@screens/promoter/ManagePromoterBookingOpportunityScreen/ManagePromoterBookingOpportunityScreen';
import { ManagePromoterProfileScreen } from '@screens/promoter/ManagePromoterProfileScreen/ManagePromoterProfileScreen';
import { OnboardPromoterScreen } from '@screens/promoter/OnboardPromoterScreen/OnboardPromoterScreen';
import { ManageTriviaChallengeScreen } from '@screens/super-admin/ManageTriviaChallengeScreen';
import { SuperAdminManageBookingOpportunityScreen } from '@screens/super-admin/SuperAdminManageBookingOpportunityScreen';
import { SuperAdminManageUpcomingEventsScreen } from '@screens/super-admin/SuperAdminManageUpcomingEventsScreen';
import { SuperAdminManageWeeklyTriviaScreen } from '@screens/super-admin/SuperAdminManageWeeklyTriviaScreen';
import { VenueRankingsScreen } from '@screens/venue/VenueRankingsScreen/VenueRankingsScreen';
import { VenueScreen } from '@screens/venue/VenueScreen/VenueScreen';
import { VenuesScreen } from '@screens/venue/VenuesScreen';

import { useLocation, useNavigate, withTabbar } from '.';
import { CoreLayout } from '../layouts/CoreLayout';
import { NoAuthCreateEditBookingOpportunityScreen } from '../screens/promoter/CreateEditBookingOpportunityScreen/NoAuthCreateBookingOpportunityScreen';

const MockTabs = () => {
  useEffect(() => {
    f7ready((f7) => {
      console.log(f7.tab, f7);
    });
  });

  return (
    <Page pageContent={false}>
      <Toolbar tabbar bottom>
        <Link href="/" tabLink="tab-1" text="Tab 1" />
        <Link href="/tab-2/" tabLink="tab-2" text="Tab 2" />
        <Link href="/tab-3/" tabLink="tab-3" text="Tab 3" />
      </Toolbar>

      <Tabs routable>
        <Tab id="tab-1">Tab 1</Tab>
        <Tab id="tab-2">Tab 2</Tab>
        <Tab id="tab-3">Tab 3</Tab>
      </Tabs>
    </Page>
  );
};

export const f7routes: ComponentProps<typeof F7App>['routes'] = [
  // {
  //   path: '/tabs/',
  //   component: MockTabs,
  //   tabs: [
  //     {
  //       path: '/',
  //       id: 'tab-1',
  //       content: `
  //         <div className="block">
  //           <h3>Tab 1</h3>
  //           <p>...</p>
  //         </div>
  //       `,
  //     },
  //     {
  //       path: '/tab-2/',
  //       id: 'tab-2',
  //       content: `
  //         <div className="block">
  //           <h3>Tab 2</h3>
  //           <p>...</p>
  //         </div>
  //       `,
  //     },
  //     {
  //       path: '/tab-3/',
  //       id: 'tab-3',
  //       content: `
  //         <div className="block">
  //           <h3>Tab 3</h3>
  //           <p>...</p>
  //         </div>
  //       `,
  //     },
  //   ],
  // },
  {
    path: '/',
    component: memo(() => {
      const token = localStorage.getItem('token');

      if (token) {
        return <CommunityScreen />;
      }

      return <LogInScreen />;
    }),
  },
  {
    path: '/login',
    component: LogInScreen,
  },
  {
    path: '/magic-link/entertainer',
    async: async ({ resolve, reject }) => {
      try {
        const { entertainer } = await fetchCurrentEntertainer(queryClient);
        if (entertainer?.title) {
          return resolve({ component: ManageEntertainerProfileScreen });
        }
        return resolve({ component: OnboardEntertainerScreen });
      } catch (e) {
        reject();
      }
    },
  },
  {
    path: '/magic-link/promoter',
    async: async ({ resolve, reject }) => {
      try {
        const { promoter } = await fetchCurrentPromoter(queryClient);
        if (promoter.title) {
          return resolve({ component: ManagePromoterProfileScreen });
        }
        return resolve({ component: OnboardPromoterScreen });
      } catch (e) {
        reject();
      }
    },
  },
  {
    path: '/magic-link/direct-message',
    redirect: ({ to, resolve }) => {
      const { currentUserId, entertainerId } = to?.query ?? {};

      if (currentUserId && entertainerId) {
        return resolve(`/chat/user/${currentUserId}/entertainer/${entertainerId}`);
      }

      return resolve('/');
    },
  },
  {
    path: '/magic-link',
    component: MagicLinkScreen,
  },
  {
    path: '/home',
    component: CommunityScreen,
  },
  {
    path: '/discover',
    component: DiscoverScreen,
  },
  {
    path: '/discover/:queryId',
    component: QueryScreen,
  },
  {
    path: '/search',
    component: SearchScreen,
  },
  { path: '/interests', component: InterestsScreen },
  { path: '/interests/:tagId', component: InterestScreen },
  { path: '/community', component: CommunityScreen },
  { path: '/community/:tab', component: CommunityScreen },
  {
    path: '/challenges',
    component: ChallengesScreen,
  },
  { path: '/notifications', component: NotificationsScreen },
  {
    path: '/events',
    component: EventsScreen,
  },
  {
    path: '/events/suggested',
    component: SuggestedEventsScreen,
  },
  {
    path: '/events/:eventId',
    component: EventScreen,
  },
  {
    path: '/events/:eventId/cleanup',
    component: EventCleanupScreen,
  },
  {
    path: '/venues',
    component: VenuesScreen,
  },
  {
    path: '/venues/request',
    component: SubmitVenueScreen,
  },
  {
    path: '/venues/:venueId',
    component: VenueScreen,
  },
  {
    path: '/promoters/:promoterId',
    component: PromoterScreen,
  },
  {
    path: '/profile',
    component: ProfileScreen,
  },
  {
    path: '/profile/edit',
    component: EditProfileScreen,
  },
  {
    path: '/profile/settings',
    component: SettingsScreen,
  },
  {
    path: '/manage/promoter',
    component: ManagePromoterProfileScreen,
  },
  {
    path: '/manage/promoter/edit',
    component: EditPromoterProfileScreen,
  },
  {
    path: '/manage/promoter/events/create',
    component: CreateEditPromoterEventScreen,
  },
  {
    path: '/manage/promoter/events/:eventId',
    component: CreateEditPromoterEventScreen,
  },
  {
    path: '/open-bookings/create-edit',
    component: CreateEditBookingOpportunitesScreen,
  },
  {
    path: '/noauth/open-bookings/:cityId/create',
    component: NoAuthCreateEditBookingOpportunityScreen,
  },
  {
    path: '/manage/promoter/open-bookings/:bookingOpportunityId',
    component: ManagePromoterBookingOpportunityScreen,
  },
  {
    path: '/manage/promoter/booking-opportunities/:bookingOpportunityId/event',
    component: CreateEditBookingOpportunityEventScreen,
  },
  {
    path: '/manage/competitions',
    component: ManageCompetitionsScreen,
  },
  {
    path: '/manage/entertainer',
    component: ManageEntertainerProfileScreen,
  },
  {
    path: '/manage/entertainer/edit',
    component: EditEntertainerProfileScreen,
  },
  {
    path: '/manage/entertainer/events/create',
    component: CreateEditEntertainerEventScreen,
  },
  {
    path: '/manage/entertainer/events/:eventId',
    component: CreateEditEntertainerEventScreen,
  },
  {
    path: '/manage/entertainer/event-requests',
    component: EventRequestsScreen,
  },

  {
    path: '/manage/entertainer/booking-opportunity/:bookingOpportunityId',
    component: ManageEntertainerBookingOpportunityScreen,
  },
  {
    path: '/manage/entertainer/booking-request/:bookingRequestId',
    component: ManageEntertainerBookingRequestScreen,
  },
  {
    path: '/manage/entertainer/competition/:campaignId',
    component: EntertainerCompetitionScreen,
  },
  {
    path: '/entertainer/onboarding',
    component: OnboardEntertainerScreen,
  },
  {
    path: '/noauth/entertainer/onboarding',
    component: OnboardEntertainerScreen,
  },
  {
    path: '/entertainer/onboarding',
    component: OnboardEntertainerScreen,
  },
  {
    path: '/entertainer/:bookingHandle',
    component: OnboardEntertainerScreen,
  },
  {
    path: '/noauth/entertainer/:bookingHandle',
    component: EntertainerDetailsScreen,
  },
  {
    path: '/noauth/entertainers/:type/:cityId/rankings',
    component: EntertainerRankingsScreen,
  },
  {
    path: '/noauth/cities/:cityId/venues/rankings',
    component: VenueRankingsScreen,
  },
  { path: '/noauth/book/:bookingHandle', component: BookingRequestScreen },
  {
    path: '/response-to/:incomingMessageId',
    component: SmsResponseScreen,
  },
  {
    path: '/sms',
    component: SmsScreen,
  },
  {
    path: '/super-admin/manage/events',
    component: SuperAdminManageUpcomingEventsScreen,
  },
  {
    path: '/super-admin/manage/trivia',
    component: SuperAdminManageWeeklyTriviaScreen,
  },
  {
    path: '/super-admin/manage/trivia/:triviaChallengeId',
    component: ManageTriviaChallengeScreen,
  },
  {
    path: '/super-admin/booking-opportunities/:bookingOpportunityId',
    component: SuperAdminManageBookingOpportunityScreen,
  },
  {
    path: '/trivia',
    component: TriviaScreen,
  },
  {
    path: '/onboarding',
    component: OnboardingBasicInfoScreen,
  },
  {
    path: '/onboarding/interests',
    component: OnboardingInterestsScreen,
  },
  {
    path: '/onboarding/critiques',
    component: OnboardingCritiquesScreen,
  },
  {
    path: '/onboarding/quick-picks',
    component: OnboardingQuickPicksScreen,
  },
  {
    path: '/critique',
    component: CritiqueScreen,
  },
  {
    path: '/quick-picks',
    component: QuickPicksScreen,
  },
  {
    path: '/noauth/quick-pick/:critiqueQuestionId',
    component: NoAuthQuickPickScreen,
  },
  {
    path: '/noauth/booking-opportunity/:bookingOpportunityId',
    component: ManageNoauthBookingOpportunityScreen,
  },
  {
    path: '/noauth/download',
    component: NoAuthDownloadScreen,
  },
  {
    path: '/conversations/:profileType',
    component: ConversationsScreen,
  },
  {
    path: '/chat/promoter/:currentPromoterId/entertainer/:entertainerId',
    component: ChatScreen,
  },
  {
    path: '/chat/entertainer/:currentEntertainerId/promoter/:promoterId',
    component: ChatScreen,
  },
  {
    path: '/chat/entertainer/:currentEntertainerId/user/:userId',
    component: ChatScreen,
  },
  {
    path: '/chat/user/:currentUserId/entertainer/:entertainerId',
    component: ChatScreen,
  },
  {
    path: '(.*)',
    component: (() => <Page>404 Page Not Found</Page>) as FC,
  },
];
